import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//Images
import enfants from "../img/photo_nathalie.jpg";
import logoEcole from "../img/NOUVEAU-LOGO-EFSS-2-VIOLET.png";
import diplome from "../img/diplome/diplome.jpg"

//Animations
import { motion } from "framer-motion";
import {
  sliderContainer,
  titleAnim,
  slider,
  pageAnimation,
  fade,
  photoAnim,
  lineAnim
} from "../animation";
import { useScroll } from "../components/useScroll";
import ScrollTop from "../components/ScrollTop";

const AboutMe = () => {
  const [ element, controls ] = useScroll();
  const [ element2, controls2 ] = useScroll();
  return (
    <Work
      style={{ background: "#fff" }}
      exit="exit"
      variants={pageAnimation}
      initial="hidden"
      animate="show"
    >

      <About>
        <Description>
          <motion.div>
            <Hide>
              <motion.h2 variants={fade} >Nathalie CARMONA </motion.h2>
              <Hide>
                <motion.div variants={lineAnim} className="line"></motion.div>
              </ Hide>
            </Hide>
            <Hide>
              <motion.h2 variants={titleAnim} > VOTRE SOPHROLOGUE</motion.h2>
            </Hide>
          </motion.div>
          <motion.p variants={fade} className="" style={{ paddingBottom: "0.5rem" }}>
            Être sophrologue c'est tout d'abord être à votre écoute, je vous aide à aller vers votre bien - être et à cibler vos besoins.
          </motion.p>
          <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>

            Je m'engage à vous accompagner avec bienveillance et sans jugements, avec respect et tolérance, dans un cadre de confiance et de confidentialité <span style={{ color: "black" }}> à  votre domicile </span>sur les communes de St Jean de Védas, Pignan, Lavérune, Fabrègues, et les environs <span style={{ color: "black" }}> ou en cabinet</span> sur St Jean de Védas ou Fabrègues.
          </motion.p>
          <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>
            <span>Sophrologue certifiée par E.F.S.S</span> – Ecole Française Supérieure de Sophrologie « Sup de Sophro», centre de formation enregistré au RNCP (Répertoire National des Certifications Professionnelles) niveau 5 de qualification reconnue par l'Etat. <br /> Je vous propose des <span>séances individuelles</span>, des <span>séances de groupe</span> et également des <span>ateliers collectifs</span>.
          </motion.p>
          <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>
            Je peux vous aider à <span>mieux vivre</span> au quotidien et rétablir une <span>harmonie émotionnelle</span>.<br />
          </motion.p>
          <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>
            <strong>Je serai ravie de vous rencontrer et d'échanger avec vous.</strong> <br />
          </motion.p>
          <motion.button variants={fade} className="AboutMe-Btn"> <Link to="contact" className="contactBtn">Contact</Link> </motion.button>
        </Description>
        <Image style={{ overflow: "visible", paddingTop: "1rem" }}>
          <motion.img variants={photoAnim} src={enfants} className="imgChildren" alt="photo of nathalie carmona" />
        </Image>
        <motion.p className="arrowDownAnimated" animate={{ y: [ 40, -10, 40 ] }} transition={{ ease: "easeOut", repeat: "Infinity", duration: 3 }} variants={fade} style={{ position: "absolute", bottom: "5%", left: "2%", fontSize: "5rem", color: "#fea654" }}>&darr;</motion.p>
      </About>

      <About style={{ marginTop: "8rem", minHeight: "auto" }}>

        <Description>
          <Hide>
            <motion.h2 variants={titleAnim} >PARCOURS ET FORMATIONS</motion.h2>
          </Hide>
          <Hide>
            <motion.div variants={lineAnim} className="line"></motion.div>
          </ Hide>
          <div className="parcoursListing" >
            <Hide>
              <Formation variants={titleAnim}>
                <Hide>
                  <FormationListing  >
                    <p>  <span variants={titleAnim}>Certificat Professionnel « PRATICIEN SOPHROLOGUE »</span></p>
                  </FormationListing>
                </Hide>
                <Hide>
                  <FormationListing>
                    <p>Formation de Sophrologue à l’ <span style={{ color: "black" }}>Ecole Française Supérieure de Sophrologie(EFSS - Sup de Sophro)</span></p>
                  </FormationListing>
                </Hide>
                <Hide>
                  <FormationListing>
                    <p>BPJEPS AGFF: « Mention forme en cours Collectifs »</p>
                  </FormationListing>
                </Hide>
                <Hide>
                  <FormationListing>
                    <p>CQP ALS AGEE: « Activités Gymniques d’Entretien et d’Expression »</p>
                  </FormationListing>
                </Hide>
                <Hide>
                  <FormationListing>
                    <p>BTS Assistante de Direction</p>
                  </FormationListing>
                </Hide>
              </Formation>
            </Hide>
            <Image>
              <motion.img variants={photoAnim} src={logoEcole} className="schoolLogo" alt="sophro school logo" />
              <motion.img variants={photoAnim} src={diplome} className="schoolLogo" alt="sophro school logo" />
              <motion.p variants={titleAnim} style={{ padding: "0" }} >Code de déontologie : <a style={{ fontSize: "1.4rem", textDecoration: "none" }} href="./code-deontologie.pdf" download="code_de_deontologie"><span>Télécharger le pdf</span></a></motion.p>
            </Image>
          </div>
          <motion.p variants={fade} style={{ paddingBottom: "0.5rem" }}>
            Depuis plusieurs années je travaille comme <span >Coach sportif </span>dans la Forme et le Bien - être, cette activité m’a permis de constater que la forme et le bien - être prennent une part de plus en plus importante dans les préoccupations quotidiennes des personnes.
          </motion.p>
          <motion.p variants={fade} style={{ padding: "0.5rem 0" }}>
            On me définit comme une <span>personne à l’écoute</span> des autres, appréciant le <span>contact humain</span> et ressentant le besoin de me sentir utile. J’ai naturellement choisi de me former à la Sophrologie et de me consacrer pleinement à ce métier, afin de proposer des accompagnements auprès des particuliers, des groupes mais aussi des sociétés.
          </motion.p>
          <motion.p variants={fade} style={{ padding: "0.5rem 0 4rem 0" }}>
            J’interviens dans les <span >entreprises, et collectivités</span> (écoles, collèges, lycées, cliniques, associations, centres sportifs, maison des séniors…). <br />
          </motion.p>
        </Description>
      </About>
      <ScrollTop />
    </Work >
  );
};

const Work = styled(motion.div)`
  min-height: 100vh;
  overflow: hidden;
  padding: 5rem 10rem;
  padding-bottom: 0;
  
  h2 {
    padding: 1rem 0rem;
  }
  
  p{
    padding: 3rem 0rem;
    color: black;
    font-size: 1.4rem;
    line-height: 150%;
  }

  @media (max-width: 1300px) {
    padding: 2rem 2rem;
  }

  @media (max-width: 500px) {
    padding: 2rem 0rem;
  }

`;

const About = styled(motion.section)`
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0rem 10rem; */
  color: white;
  @media (max-width: 1300px) {
    display: block;
    /* padding: 2rem 2rem; */
    text-align: center;
  }

  @media (max-width: 950px) {
    .arrowDownAnimated {
      display: none;
    }
  }

`;

const Description = styled(motion.div)`
  flex: 1;
  padding-right: 5rem;
  z-index: 2;
    .line {
      height: 0.2rem;
      background: #fea654;
      margin-bottom: 3rem;
    }
    img {
      width: 100%;
      height: 70vh;
      object-fit: cover;
    }

    h2 {
      padding-top: 0;
    } 
   
    .parcoursListing {
      display: flex;
      justify-content: center; 
      align-items: center 
    }

    .AboutMe-Btn {
      margin-top: 2rem;
    }

  @media (max-width: 1300px) {
    padding: 0;
    button {
      margin: 2rem 0rem 5rem 0rem;
    }

    p {
      text-align: justify;
      max-width: 80%;
      margin: 0 auto;
    }

    .parcoursListing {
      display: flex;
      flex-direction: column;

      ul {
        width: 80%;     
        margin: 0 auto;
       margin-bottom: 2rem;
      }
      p {
        max-width: 100%;
      }
    }
  }

    @media (max-width: 800px) { 

    h2 {
      font-size: 2rem;
    }
  }

    @media (max-width: 500px) { 

    p {
      max-width: 90%;
    }
  }

`;

export const Image = styled.div`
  z-index: 2;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  
  img {
    height: 60vh;
    object-fit: cover;
    /* width: 100%;
    height: 80vh;
    object-fit: cover; */
  }

  .imgChildren {
    border-radius: 3%; 
    overflow: visible; 
    display: block; 
    margin: 0 auto;
    /* border-radius: 50%; 
    overflow: visible; 
    display: block; 
    margin: 0 auto;
    width: 500px;
    height: 500px */
  }

  img.schoolLogo {
    object-fit: contain;
    height: auto;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 20rem;
    border-radius: 5px;
  }

    @media (max-width: 1300px) {

      img {
        height: 50vh;
      }
      img.schoolLogo {
      width: 50%;
      }
    }

    @media (max-width: 800px) { 
    .imgChildren {
      height: 30vh;
    }
  }
`;

const Formation = styled(motion.ul)`
  /* margin-top: 2rem; */
`

const FormationListing = styled(motion.li)`
  padding: 1rem 2rem 1rem 0;
  list-style: none;
  p {
    padding: 0
  }
`

const Hide = styled.div`
  overflow: hidden;
`;

export default AboutMe;
